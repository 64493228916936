import React from 'react';

// reactstrap components
import { Button, Form, Input, Container, Row, Col } from 'reactstrap';

// core components
import WhiteNavbar from 'components/Navbars/WhiteNavbar.js';
import FooterWhite from 'components/Footers/FooterWhite.js';

function ContactUs() {
  const [success, setSuccess] = React.useState(false);

  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('contact-us');
    if (window.location.search.includes('success=true')) {
      setSuccess(true);
    }
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('contact-us');
    };
  }, []);

  return (
    <>
      <WhiteNavbar />
      <div className='main'>
        <div className='section section-gray'>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto text-center' md='8'>
                <h2 className='title'>Get in touch with us</h2>
                <p>
                  ShowingsRE offers a powerful platform to simplify scheduling showings. Agents, Teams, Brokerages, and MLS's can personalize the application
                  with their brand and boost productivity with time saved! Reach out today!
                </p>
              </Col>
            </Row>
            <Row>
              <h4 className='title'>
                Email us at:
                <a href='mailto:support@zippq.com'>ShowingsRE Support</a>
              </h4>
            </Row>
            {success && <p style={{ color: 'green' }}>Thanks for your submission! </p>}
            <Row>
              <Col className='ml-auto mr-auto text-center' md='6'>
                <Form className='contact' name='contact-us' data-netlify='true' netlify-honeypot='bot-field' method='POST' action='/contact-us'>
                  <Row>
                    <Col md='6'>
                      <Input type='hidden' name='form-name' value='contact-us' />
                      <Input placeholder='Name' type='text' name='name' />
                    </Col>
                    <Col md='6'>
                      <Input placeholder='Email' type='email' name='email' />
                    </Col>
                  </Row>
                  <Row>
                    <Col md='6'>
                      <Input placeholder='City' type='text' name='city' />
                    </Col>
                    <Col md='6'>
                      <Input placeholder='State' type='text' name='state' />
                    </Col>
                  </Row>
                  <Input placeholder='Company and/or a brief message' rows='7' type='textarea' name='message' />
                  <Row>
                    <Col className='ml-auto mr-auto' md='6'>
                      <Button type='submit' block className='btn-round' color='primary'>
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <FooterWhite />
    </>
  );
}

export default ContactUs;
