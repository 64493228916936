import React from 'react';
import { Link, useHistory } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap';
import testImage from '../../assets/img/presentation-page/ipadTourMode__.jpg';

// core components

function SectionBlog() {
  const history = useHistory();

  function handleBlogSelect(path) {
    history.push(path);
  }

  return (
    <>
      <div className='section secion-blog cd-section' id='blogs'>
        <div className='blog-4'>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto' md='8'>
                <h2 className='title text-center'>Latest Blogposts </h2>
                <br />
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <Card className='card-plain card-blog text-center'>
                  <div className='card-image'>
                    {/* <a href='#pablo' onClick={(e) => e.preventDefault()}> */}
                    <Link to='/blogOne'>
                      <img src={testImage} alt='' />
                    </Link>
                    {/* <img alt='...' className='img img-raised' src={require('assets/img/faces/val-vesa.jpg').default} /> */}
                  </div>
                  <CardBody>
                    <h6 className='card-category text-warning'>Travel</h6>
                    <CardTitle tag='h3'>
                      <a
                        href='#pablo'
                        onClick={() => handleBlogSelect('blogOne')}
                      >
                        Learning different cultures through travel
                      </a>
                    </CardTitle>
                    <p className='card-description'>
                      A wonderful serenity has taken possession of my entire
                      soul, like these sweet mornings of spring which I enjoy
                      with my whole heart. I am alone, and feel the charm of
                      existence in this spot and it is amazing!
                    </p>
                    <br />
                    <Button
                      className='btn-round'
                      color='warning'
                      href='./blogOne'
                      onClick={() => handleBlogSelect('blogOne')}
                    >
                      Read More
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md='6'>
                <Card className='card-plain card-blog text-center'>
                  <div className='card-image'>
                    <Link to='/blogTwo'>
                      <img
                        alt='...'
                        className='img img-raised'
                        src={require('assets/img/presentation-page/home-hunter.png')}
                      />
                    </Link>
                  </div>
                  <CardBody>
                    <h6 className='card-category text-info'>Travel</h6>
                    <CardTitle tag='h3'>
                      <a
                        href='#pablo'
                        onClick={() => handleBlogSelect('blogTwo')}
                      >
                        Kick-Ass ways to disappear like a Ninja!
                      </a>
                    </CardTitle>
                    <p className='card-description'>
                      In the end, the judge ruled that Levandowski could be
                      brought in and examined, but that each question asked to
                      him would be vetted in advance and should have some basis
                      in evidence.
                    </p>
                    <br />
                    <Button
                      className='btn-round'
                      color='primary'
                      href='#pablo'
                      onClick={() => handleBlogSelect('blogTwo')}
                    >
                      Read More
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <Card className='card-plain card-blog text-center'>
                  <div className='card-image'>
                    <a href='#pablo' onClick={(e) => e.preventDefault()}>
                      <img
                        alt='...'
                        className='img img-raised'
                        src={require('assets/img/sections/federico-beccari.jpg')}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <h6 className='card-category text-danger'>Lifestyle</h6>
                    <CardTitle tag='h3'>
                      <a href='#pablo' onClick={(e) => e.preventDefault()}>
                        We will breathe clean air and exercise
                      </a>
                    </CardTitle>
                    <p className='card-description'>
                      Don't be scared of the truth because we need to restart
                      the human foundation in truth And I love you like Kanye
                      loves Kanye I love Rick Owens’ bed design but the back is
                      too high for the beams and angle of the ceiling...
                    </p>
                    <br />
                    <Button
                      className='btn-round'
                      color='danger'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      Read More
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md='6'>
                <Card className='card-plain card-blog text-center'>
                  <div className='card-image'>
                    <a href='#pablo' onClick={(e) => e.preventDefault()}>
                      <img
                        alt='...'
                        className='img img-raised'
                        src={require('assets/img/sections/pedro-lastra.jpg')}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <h6 className='card-category text-success'>Best Seller</h6>
                    <CardTitle tag='h3'>
                      <a href='#pablo' onClick={(e) => e.preventDefault()}>
                        Readers Pick of The Month
                      </a>
                    </CardTitle>
                    <p className='card-description'>
                      “Raising equity is very expensive” In essence, it lets new
                      consumer businesses apply to raise funding on its
                      platform, and gives investors a new way to find and back
                      those tricks to finance their growing businesses.
                    </p>
                    <br />
                    <Button
                      className='btn-round'
                      color='success'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      Read More
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END BLOGS 4 ********* */}
      </div>
    </>
  );
}

export default SectionBlog;
