/*!

=========================================================
* Paper Kit PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

// styles
import 'assets/css/bootstrap.min.css';
import 'assets/scss/paper-kit.scss';
import 'assets/demo/demo.css';
import 'assets/demo/react-demo.css';
// pages

import Presentation from 'views/Presentation.js';
import AboutUs from 'views/examples/AboutUs.js';
import SectionBlog from 'views/examples/SectionBlog';
import SectionPricing from 'views/examples/SectionPricing';

import ContactUs from 'views/examples/ContactUs.js';

import Error404 from 'views/examples/Error404.js';
import Error422 from 'views/examples/Error422.js';
import Error500 from 'views/examples/Error500.js';
import BlogOne from 'views/examples/blogs/BlogOne';
import BlogTwo from 'views/examples/blogs/BlogTwo';

// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path='/about-us' render={(props) => <AboutUs {...props} />} />
      <Route exact path='/contact-us' render={(props) => <ContactUs {...props} />} />
      <Route exact path='/blog' render={(props) => <SectionBlog {...props} />} />
      <Route exact path='/pricing' render={(props) => <SectionPricing {...props} />} />
      <Route exact path='/blogOne' render={(props) => <BlogOne {...props} />} />
      <Route exact path='/blogTwo' render={(props) => <BlogTwo {...props} />} />
      <Route path='/error-404' render={(props) => <Error404 {...props} />} />
      <Route path='/error-422' render={(props) => <Error422 {...props} />} />
      <Route path='/error-500' render={(props) => <Error500 {...props} />} />
      <Route path='/' render={(props) => <Presentation {...props} />} />

      <Redirect to='/' />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
