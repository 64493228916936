/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';
// core comments

function PresentationHeader() {
  return (
    <>
      <div className='wrapper'>
        <div
          className='page-header section-dark'
          style={{
            backgroundImage:
              'url(' +
              require('assets/img/presentation-page/neighborhood.jpg') +
              ')',
          }}
        >
          <h6 className='category category-absolute'>{''}</h6>
          {/* <div className="fog-low">
            <img alt="..." src={require("assets/img/sections/fog-low.png")} />
          </div>
          <div className="fog-low right">
            <img alt="..." src={require("assets/img/sections/fog-low.png")} />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default PresentationHeader;
