import React from 'react';
// reactstrap components
import { Row, Col } from 'reactstrap';

// core components

function SectionCards() {
  return (
    <>
      <div className='section section-cards section-gold'>
        <div className='codrops-header'>
          <Row>
            <Col lg='4' md='6'>
              <div className='section-description'>
                <h2 className='title'>Integrated Home Search</h2>
                <h4 className='category'>Hotsheets - Yes!</h4>
                <h5 className='description'>
                  An integrated home search that clients will want to use!
                  Powerful fuzzy search and filtering and saved searches with
                  realtime alerts for new property matches to both agents and
                  clients
                </h5>
              </div>
            </Col>
          </Row>
        </div>
        <section className='section-intro'>
          <div className='isolayer isolayer--deco1 isolayer--shadow js'>
            <ul className='grid grid--loaded'>
              <li className='grid__item first-card'>
                <a
                  className='grid__link'
                  href='#pablo'
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt='...'
                    className='grid__img layer'
                    src={require('assets/img/presentation-page/try/longHomeSearch.png')}
                  />
                </a>
              </li>

              <li className='grid__item fifth-card'>
                <a
                  className='grid__link'
                  href='#pablo'
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt='...'
                    className='grid__img layer'
                    src={require('assets/img/presentation-page/try/mapSchedule.png')}
                  />
                </a>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}

export default SectionCards;
