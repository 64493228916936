import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components

function SectionContent() {
  return (
    <>
      <div id='fadeInAnim'>
        <div className='section section-content section-gray'>
          <Container>
            <Row>
              <Col md='7'>
                <div className='image-container'>
                  <img
                    alt='...'
                    className='img'
                    src={require('assets/img/presentation-page/ipadTourMode__.jpg')}
                  />
                  <img
                    alt='...'
                    className='area-img add-animation'
                    src={require('assets/img/presentation-page/selectTour.png')}
                  />
                  <img
                    alt='...'
                    className='info-img add-animation'
                    src={require('assets/img/presentation-page/tourModePhone__.jpg')}
                  />
                </div>
              </Col>
              <Col md='4'>
                <div className='section-description'>
                  <h2 className='title' style={{ marginRight: 0 }}>
                    Did We Mention Tour Mode?
                  </h2>
                  <h4 className='category'>
                    A tour itinerary is created for each day with multi-colored
                    direction segments that start from your location - seamless
                    navigation!
                  </h4>
                  <h5 className='description'>
                    Agent and client views both have this feature to keep the
                    tour moving and on schedule -
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionContent;
