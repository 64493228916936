import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

function Error500() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("error-500");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("error-500");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div
        className="background-img"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/presentation-page/greenSLCpano.jpg") +
            ")",
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <h1 className="title">
               500 <br />
              <p className="error-msg">
                We're sorry, but something went wrong. We are working an fixing
                this. <br />
                Please refresh the page in a couple of seconds.
              </p>
            </h1>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Error500;
