import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';

// core components
import ColorNavbar from 'components/Navbars/ColorNavbar.js';
import AboutUsHeader from 'components/Headers/AboutUsHeader.js';
import FooterBlack from 'components/Footers/FooterBlack.js';

function AboutUs() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('about-us');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('about-us');
    };
  });
  return (
    <>
      <ColorNavbar />
      <AboutUsHeader />
      <div className='main'>
        <div className='section'>
          <Container>
            <h3 className='title-uppercase'>We build great products</h3>
            <p>
              ShowingsRE exists to make real estate better. More than just
              another widget to support one piece of the workflow. ShowingsRE
              Scheduler is flexible ecosystem to get things done, manage
              clients, schedule showings, plan tours, receive feedback and much
              more.
            </p>
            <p></p>
            <h3 className='title-uppercase'>
              We
              <i className='fa fa-heart heart mr-3 ml-1' />
              What We Do
            </h3>
            <p>
              ShowingsRE offers the industry leading showing scheduler solution
              - Adapt and Innovate - Relentless focus on experience and user
              success - Repeat -{' '}
            </p>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default AboutUs;
