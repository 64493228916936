import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';
// core components

function SectionSummary() {
  return (
    <>
      <div className='section section-dark section-summary'>
        <Container>
          <Row>
            <h1 className='info-title'>
              ShowingsRE - Innovation for Real Estate
            </h1>
            <h3>
              A dynamic platform designed to line up showings with ease and stay
              connected with clients to keep pace with modern realestate.
            </h3>
          </Row>
          <Row>
            <Col md='4'>
              <div className='info'>
                <div className='icon icon-danger'>
                  <i className='nc-icon nc-user-run' />
                </div>
                <div className='description'>
                  <h4 className='info-title'>Scheduling Simplified</h4>
                  <p>
                    ShowingsRE's platform makes scheduling home tours a snap -
                    designed by agents for agents to rock out tours and get
                    clients into the home of their dreams.
                  </p>
                </div>
              </div>
            </Col>
            <Col md='4'>
              <div className='info'>
                <div className='icon icon-danger'>
                  <i className='nc-icon nc-air-baloon' />
                </div>
                <div className='description'>
                  <h4 className='info-title'>Agents, Teams, Brokerages, MLS</h4>
                  <p>
                    ShowingsRE works for agents, teams, and brokerages as a
                    stand-alone service or fully integrated with a Multiple
                    Listing Service
                  </p>
                </div>
              </div>
            </Col>
            <Col md='4'>
              <div className='info'>
                <div className='icon icon-danger'>
                  <i className='nc-icon nc-palette' />
                </div>
                <div className='description'>
                  <h4 className='info-title'>Any Device Anytime</h4>
                  <p>
                    Buyer or Seller - A flexible platform supports different
                    work styles and handles challenges with scheduling issues in
                    real time
                  </p>
                  <p>Last Minute Confirmation? No Problem!</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionSummary;
