import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';

// core components

function AboutUsHeader() {
  return (
    <>
      <div
        className='page-header page-header-small'
        style={{
          backgroundImage: 'url(' + require('assets/img/presentation-page/greenSLCpano.jpg') + ')',
        }}
      >
        {/* <div className="filter filter-danger" /> */}
        <div className='content-center'>
          <Container>
            <h1>
              Experience ShowingsRE
              <br />
            </h1>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;
