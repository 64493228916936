import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components

function SectionTestimonials() {
  return (
    <>
      <div className='section section-testimonials'>
        <Container fluid>
          <Row>
            <Col className='ml-auto mr-auto text-center' md='8'>
              <h2 className='title'>Ready To Get Started with ShowingsRE Today?</h2>
              <img src={require('assets/img/presentation-page/optimized_zqpalm.jpg')} alt='palm holding home hologram' />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionTestimonials;
