import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components

function SectionComponents() {
  return (
    <>
      <div className='section section-components section-dark'>
        <Row>
          <Col lg='6' md='12'>
            <div className='image-container'>
              <img alt='...' className='components-macbook' src={require('assets/img/presentation-page/try/ipadMultiSched.png')} />
              <img alt='...' className='table-img' src={require('assets/img/presentation-page/floater-ipadMapSched.png')} />

              <img alt='...' className='coloured-card-btn-img' src={require('assets/img/presentation-page/contacts.png')} />
              <img alt='...' className='coloured-card-img' src={require('assets/img/presentation-page/notifications.png')} />
            </div>
          </Col>
          <Col className='ml-auto mr-auto' lg='4' md='10'>
            <Container className='basic-container'>
              <h3 className='title'>Your Brand, Your Way </h3>
              <h6 className='category'>
                White label branding, allows agents, teams, brokerages or MLS to offer a personalized experience and nurture client relationships
              </h6>
              <h5 className='description'>Boost Productivity! ShowingsRE's powerful platform saves time for everyone</h5>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionComponents;
