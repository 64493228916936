import React from 'react';
import { Link } from 'react-router-dom';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import { NavbarBrand, Navbar, Nav, Container, UncontrolledTooltip } from 'reactstrap';
// core components

function WhiteNavbar() {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();
  });
  if (collapseOpen) {
  }
  return (
    <>
      {bodyClick ? (
        <div
          id='bodyClick'
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className='fixed-top' expand='lg' id='navbar-main' color='transparent'>
        <Container>
          <div className='navbar-translate'>
            <Nav>
              <NavbarBrand id='navbar-brand' to='/' tag={Link}>
                <img id='nav-logo' src={require('assets/img/faces/showingsRE.png')} alt='' />
              </NavbarBrand>
              <UncontrolledTooltip placement='bottom' target='navbar-brand'>
                ShowingsREHome
              </UncontrolledTooltip>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
